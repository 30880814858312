import Breadcrumbs from "components/Common/Breadcrumb";
import Notifications from "components/Notifications";
import DeleteConfirmationModel from "components/delete-confirmation-model";
import { getCode, userModuleData } from 'helpers/basic_helper';
import { MASTER_OPTIONS_URL } from "helpers/url_helper";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import {
    Col,
    Container,
    Row
} from "reactstrap";
import { masterOptions } from 'store/actions';
import { GetHelper, SubmitHelper } from '../CallHelper';
import FormComponent from "../FormComponent";
import Datatable from "components/Datatables/datatable"
import { Link } from "react-router-dom";

const isType = 'states'
const States = props => {
    const dispatch = useDispatch()
    const { DDMasters } = useSelector(state => state.Master);
    const [open, isOpen] = useState(false)
    const [editableObj, setEditableObj] = useState(null)
    const [dataRows, setDataRows] = useState([])
    const [loading, setLoading] = useState(false)
    const [isDeleteModel, setIsDeleteModel] = useState(false)
    const [pagination, setPagination] = useState({page: 1, sizePerPage: 10, searchTerm:''});

    useEffect(() => {getData()}, [pagination])
    
    const [isEdit, setIsEdit] = useState(false)
    const [isActions, setIsActions] = useState(false)
    const [isActionColumn, setIsActionColumn] = useState(false)


    useEffect(async() => {
        const data =  await userModuleData('masters', 'state')
        // setIsActions(data)
        if(data && (data?.edit || data?.delete)){
            // setIsActionColumn(true)
        }
    }, [])

    /**
     * Get All Rows
     * @param {row_id} ID 
     * @method {get} GET 
     */
    const getData = async(ID = false) => {
        setLoading(true)
        const res = await GetHelper(`${process.env.REACT_APP_STATES}&limit=${pagination.sizePerPage}&page=${pagination.page}&search=${pagination.searchTerm}`, ID)
        if(ID){
            setEditableObj(...res)
            isOpen(true)
            // setIsEdit(false)
        }else{
            setDataRows(res)
        }
        setLoading(false)
        if(res === undefined){
            setError(true)
        }
    }

    useEffect(() => {
        dispatch(masterOptions({url:MASTER_OPTIONS_URL}))
        getData()
    },[])

    /**
     * Submit or Update data Api Call
     * @param {Form Data} data 
     * @method {post} POST 
     */
    const submitForm = async(data) => {
        const checkCode = dataRows?.rows.filter(v => (v.code).toUpperCase() == (data.target.code.value).toUpperCase())
        if(data?.target?.id?.value =='' && checkCode.length > 0) {
            Notifications('please enter unique code.!', 'error')
            return;
        }
        const res = await SubmitHelper(`${process.env.REACT_APP_STATES}`, {
            "title": data.target.title.value,
            "code": data.target.code.value,
            "country": data.target.country.value
        }, data?.target?.id?.value || null);

        if(res){
            getData()
            isOpen(false)
            setEditableObj(null)
            Notifications('You have successfully added a State.!')
        }
    }
    
    /**
     * Get Single row using url params ?q=?
     * @param {Row_id} q 
     * @method {get} GET 
     */
    const handleEdit = q => {
        if(q > 0){
            // setIsEdit(q)
            getData(q)
        }
    }

    /**
     * Chnage Row Status By ID and condition Based Status passing,
     * @param {row_id} q 
     * @param {row Status} status 
     * @method {del} DELETE 
     */
    const handleRemove = async(q, status, code) => {
        if(q > 0) {
            setIsDeleteModel({url: process.env.REACT_APP_STATES, q, status, code})
        }
    }
    const hideModel = () => {
        setIsDeleteModel(false)
        getData()
    }

    /**
     * DataTable with Condition based row formating
     */
    let datatableData = [
        {
            dataField: 'id',
            text: 'Id'
        }, {
            dataField: 'title',
            text: 'State'
        }, {
            dataField: 'code',
            text: 'Code'
        },{
            dataField: 'country_title',
            text: 'Country'
        }, {
            dataField: 'actions',
            text: "",
            isDummyField: true,
            // formatExtraData: [ btnLoading.wpBtn, btnLoading.pdfBtn ],
            formatter: (cell, row) => <>
                <Link to="#" className="btn btn-outline-secondary btn-sm edit" onClick={() => handleEdit(row.id)} title="Edit" ><i className={`fas fa-pencil-alt`} /></Link>
                {" "}
                <Link to="#" className={`btn btn-${row.status == 0 ? "danger" : "success"} btn-sm edit`} onClick={() => handleRemove(row.id, row.status == 1 ? 0 : 1, row.code)} title={row.status == 0 ? 'delete' : 'restore'} ><i className={`fas fas fa-trash-alt`} /></Link>
            </>
        }
    ];

    /**
     * HTML Form Columns 
     */
    let htmlFormFields  = [
        {
            name: "id",
            required: false,
            type: 'hidden',
            value: editableObj?editableObj.id : '',
            col:12
        },{
            name: "title",
            label: "State Name",
            placeholder: "State Name",
            required: true,
            type: 'header',
            value: editableObj?editableObj.title : '',
            col:12
        },{
            name: "code",
            label: "Code",
            placeholder: "Code",
            required: true,
            type: 'header',
            value: editableObj?editableObj.code:getCode("states", dataRows?.length),
            col: 12
        },{
            name: "country",
            label: "Country",
            placeholder: "Country",
            required: true,
            type: 'select',
            options: DDMasters?.countries?.map(v => ({'label': v.title, 'value': v.code})),
            value: editableObj? DDMasters?.countries?.filter(v => v.code == editableObj.country).map(v => ({'label': v.title, 'value': v.code})) : '',
            col:12
        }
    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | {isType}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Masters" breadcrumbItem={isType} />
                    <Row>
                        <Col xl="12">
                            <Datatable
                                keyField={'id'}
                                title={`Countries`}
                                isAdd={true}
                                placeholder={'Search by name, code'}
                                isSearch={true}
                                handleAddButton={isOpen}
                                columns={datatableData}
                                rows={dataRows?.rows || []}
                                rowsLength={parseInt(dataRows?.total_rows) || 0}
                                loading={loading}
                                ssr={setPagination}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            
            {/* Datatables */}
            {
                open && 
                <FormComponent
                    title={`${editableObj ? 'Edit' : 'Add'} State`}
                    Position={"right"}
                    isOpen={open}
                    fileds={htmlFormFields}
                    editableObj={editableObj}
                    loading={loading}
                    onDrawerClose={() => {
                        setEditableObj(null)
                        isOpen(!open)
                    }}
                    submitForm={submitForm}
                />
            }
            {/* End Datatables */}

            { isDeleteModel && <DeleteConfirmationModel isOpen={true} changeState={hideModel} code={isDeleteModel} /> }

        </React.Fragment>
    )
}

export default States
