import { get, post } from 'helpers/api_helper';
import { ORDER_URL, PRODUCT_URL } from 'helpers/url_helper';
import moment from "moment";
import React, { useEffect, useState } from "react";
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { Modal } from "reactstrap";
import { addOrder, getAllOptions } from 'store/actions';
import { AddProduct, Trials } from "./components";
import SubDoctor from './components/add-sub-doctor';
import Teeth from './components/teeth';
import ValidateModal from './components/patientvalidatemodal';

const isType = false
let t = null
const _priorityOptions = JSON.parse(process.env.REACT_APP_ORDER_PRIORITY)
const _trials = JSON.parse(process.env.REACT_APP_ORDER_TRIALS)
const _articulatorTag = JSON.parse(process.env.REACT_APP_ORDER_ARTICULARTAGS)

const OrderForm = ({ Position, isOpen, onDrawerClose, title, width = 100, editableObj, client, worktype = 'new', isEditData}) => {
    const dispatch = useDispatch()
    const { categories, category_types, product_list } = useSelector(state => state.Orders);
    const [clientData, setClientData] = useState(false)
    const [priority, setPriority] = useState(0)
    const [isFullMouth, setIsFullMouth] = useState(0)
    const [options, setOptions] = useState(false)
    const [shadesArray, setShadesArray] = useState([])
    const [trailsArray, setTrailsArray] = useState([])
    const [modalType, setModalType] = useState(false)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [productAdded, setProductAdded] = useState([])
    const [isLoadComplete, setIsLoadComplete] = useState(false)
    const [subDoctorsOptions, setSubDoctorsOptions] = useState([])
    const [validationData, setValidationData] = useState(null)

    const [order, setOrder] = useState({
        "worktype": editableObj && isEditData ? editableObj.worktype : worktype,
        "order_id": (editableObj && isEditData) ? editableObj.id: "",
        "patient_name": editableObj ? editableObj.patient_name: "",
        "patient_age": editableObj ? editableObj.patient_age : "",
        "case_no": editableObj ? editableObj.case_no : "",
        "client": editableObj ? editableObj?.client: client,
        "order_number": editableObj ? editableObj.order_number : "",
        "in_date": editableObj ? moment(editableObj.in_date).format('DD-MM-YYYY HH:mm') : moment().format('DD-MM-YYYY HH:mm'),
        "order_date": editableObj ? moment(editableObj.order_date).format('DD-MM-YYYY HH:mm') : moment().format('DD-MM-YYYY HH:mm'),
        "due_date": editableObj ? moment(editableObj.due_date).format('DD-MM-YYYY HH:mm') : "",
        "priority": editableObj ? editableObj.priority: 0,
        "shades": [],
        "shade_note": (editableObj && editableObj.note)? editableObj.note: "",
        "trials": [],
        "articulator_tag": (editableObj && editableObj.articular) ? editableObj.articular : "",
        "sub_doctor": (editableObj && editableObj.doctor) ? editableObj.doctor : "",
        "correction_template": (editableObj && editableObj.correction_template) ? editableObj.correction_template : "",
        "remark": (editableObj && editableObj.note) ? editableObj.note : "",
        "lab_department": editableObj ? editableObj.department : "",
        "enclosure": {},
        "manufacturer":"",
        "employee": "",
        "products": editableObj ? editableObj?.products : [],
        "drop_location": ""
    })

    useEffect(() => {
        if(Object.keys(options)?.length){
            setIsLoadComplete(true)
        }
    }, [options])

    useEffect(() => {
        if(editableObj?.id){
            dispatch(getAllOptions({"url": `${PRODUCT_URL}/options`, "isType": false}))
        }
    }, [])

    useEffect(() => {
        getData(editableObj?.client)
        if(editableObj?.shade){
            setShadesArray(JSON.parse(editableObj?.shade))
        }
        if(editableObj?.trial){
            setTrailsArray(editableObj.trial.split(','))
            setOrder(prev => ({...prev, trials: editableObj.trial}))
        }
        if(editableObj?.articular){
            const a = _articulatorTag.filter(v => v.id == editableObj?.articular)
            setOrder(prev => ({...prev, articulator_tag: editableObj?.articular}))
        }
        if(editableObj?.enclosure){
            const a = JSON.parse(editableObj?.enclosure)
            setOrder(prev => ({...prev, enclosure: a}))
        }

        if(editableObj?.products){
            const b = editableObj?.products.map(v => {
                v.teeth = typeof v.teeth == 'string' ? JSON.parse(v.teeth) : v.teeth,
                v.category = v.product_category,
                v.categoryTitle = v.categoryTitle,
                v.category_type = v.product_type,
                v.categoryTypeTitle = v.categoryTypeTitle,
                v.product_id = v.product_id,
                v.productListTitle = v.productListTitle,
                v.unitPrice = v.unitPrice
                return v
            })
            setProductAdded(b)
        }

        if(editableObj?.products){
            let tempCountUnits = 0
            let tempOrderValue = 0
            
            editableObj?.products?.map( (v, i) => {
                tempCountUnits += v.teeth.length
                tempOrderValue += ( v.teeth.length * parseFloat(v.unitPrice) ) 
            })
            setOrder(c => ({...c, units: tempCountUnits, orderValue: tempOrderValue}))
        }
    }, [editableObj])

    const getData = async(id) => {
        if(id){
            const res = await get(`api/orders/client/${id}`)
            if(res.status == 200){
                getSubDoctors(id);
                setClientData(res.response.client)
                setOptions(res.response.options)
                
                if(worktype == 'new')
                    setOrder(prev => ({...prev, client: res.response.client.code, case_no: res.response.client.caseNo}))
            }
        }
    }

    const getSubDoctors = async(clientID) => {
        const res = await get(`api/clients/getsubdoctor?client=${clientID}&option=1`);
        if (res?.status === 200) {
            if (editableObj.doctor) {
                const doc = res?.response?.map(v => {
                    if (v.value === editableObj.doctor) {
                        return { ...v, selected: true };
                    }
                    return v;
                });
                setSubDoctorsOptions(doc);
            } else {
                setSubDoctorsOptions(res?.response);
            }
        }        
    }

    useEffect(() => {
        getData(client)
    },[client])

    useEffect(() => {
        if(shadesArray != null){
            setOrder(prev => ({...prev, shades:shadesArray}))
        }
    }, [shadesArray])
    
    const getTitle = () => {
        if(modalType){
            function ucfirst(str) {
                return str.charAt(0).toUpperCase() + str.slice(1);
            }
            
            let title = modalType?.replace('_', "")
            return ucfirst(title)
        }
    }

    const encCB = (encData) => {
        if(encData != null) setOrder(prev => ({...prev, enclosure: encData}))
    }

    const getProducts = (data) => {
        setProductAdded(prev => [...prev, data])
    }

    const updateOrderValue = () => {
        let tempCountUnits = 0
        let tempOrderValue = 0
        if(productAdded.length){
            productAdded?.map( (v, i) => {
                if(v.aligners){
                    tempCountUnits = tempCountUnits + v.units
                    tempOrderValue = tempOrderValue + ( v.units * parseFloat(v.unitPrice) )
                }else{
                    tempCountUnits = tempCountUnits + v.teeth.length
                    tempOrderValue = tempOrderValue + ( v.teeth.length * parseFloat(v.unitPrice) )
                }
            })
        }
        setOrder(c => ({...c, units: tempCountUnits, orderValue: tempOrderValue, products: productAdded}))
    }

    useEffect(() => {
        updateOrderValue();
    }, [productAdded])

    const addOrderMethods = () =>{
        const callback = () =>{
            onDrawerClose()

            setOrder({
                "worktype": "new",
                "order_id": "",
                "patient_name": "",
                "patient_age": "",
                "case_no": "",
                "client": "",
                "order_number": "",
                "in_date": moment().format('DD-MM-YYYY HH:mm'),
                "order_date": moment().format('DD-MM-YYYY HH:mm'),
                "due_date": "",
                "priority": 0,
                "shades": [],
                "shade_note": "",
                "trials": [],
                "articulator_tag": "",
                "sub_doctor":  "",
                "correction_template": "",
                "remark": "",
                "lab_department": "",
                "enclosure": {},
                "manufacturer":"",
                "employee": "",
                "products": [],
                "drop_location": ""
            })
        }

        if(order?.priority == 0){
            alert('Please select priority');
            return ;
        }

        const orderSummary = order.products.reduce((summary, product) => {
            const price = parseFloat(product.unitPrice);
            const units = parseInt(product.units);
            const productValue = price * units;
            summary.totalOrderValue += productValue;
            summary.totalUnits += units;
            return summary;
        }, { totalOrderValue: 0, totalUnits: 0 }); // Initial summary object with totalOrderValue and totalUnits
        
        dispatch(addOrder({Obj:{...order, order_value:orderSummary.totalOrderValue, orderValue:orderSummary.totalOrderValue, units:orderSummary.totalUnits}, url: `${ORDER_URL}`, isType:isType, callback:callback}))
    }

    const removeProductFromOrder = (product, index) => {
        const products = productAdded.filter((v, i) => i !== index)
        setProductAdded(products)
    }

    let badgeColor;
    useState(() => {
        switch(worktype){
            case 'corr':
                badgeColor = 'bg-warning';
            case 'redo':
                badgeColor = 'bg-danger';
            default:
                badgeColor = 'bg-success';
        }
    }, [])

    const getAligners = (data) => typeof data === 'string' ? JSON.parse(data) : data;

    const handlePriceEdit = (data, oldIndex) => {
        const updatedProducts = order.products.map((product, index) => {
            if (index === oldIndex) {
                let temp ={
                    ...product,
                    unitPrice: data.target.value,
                    unit_price: data.target.value,
                };
                return temp;
            }
            return product;
        });
    
        setOrder((prevState) => ({
            ...prevState,
            products: updatedProducts,
        }));
    }

    const handlePropertyChange = (data, oldIndex, propertyName, keyName) => {
        const updatedProducts = order.products.map((product, index) => {
            if (index === oldIndex) {
                let temp ={
                    ...product,
                    [keyName]: data.value,
                    [`${propertyName}Title`]: data.label
                };
                if(keyName == 'product_id'){
                    temp.unitPrice = data.price
                    temp.unit_price = data.price
                }
                return temp;
            }
            return product;
        });
    
        setOrder((prevState) => ({
            ...prevState,
            products: updatedProducts,
        }));
    
        // Dispatch additional actions based on the property change
        let url = '';
        let isType = '';
    
        if (propertyName === 'category') {
            url = `${PRODUCT_URL}/options/types?q=${data.value}`;
            isType = 'types';
        } else if (propertyName === 'categoryType') {
            url = `${PRODUCT_URL}/options/product?q=${data.value}&c=${clientData.code}`;
            isType = 'product';
        } 

        // Dispatch the action with the determined URL and isType
        dispatch(getAllOptions({ url, isType }));
    };

    const handleChangeTeeth = (data, oldIndex) => {
        const updatedProduct = order.products.map((product, index) => {
            if (index === oldIndex) {
                let t = [];
                if(product.teeth?.length > 0) {
                    if(product.teeth.includes(data) && product?.teeth?.length == 1 ){
                        return {
                            ...product,
                            teeth: [...product.teeth, data]
                        };
                    }

                    if (product.teeth.includes(data) && product?.teeth?.length != 1 ) {
                        t = product.teeth.filter(item => item !== data);
                    } else {
                        t = [...product.teeth, data];
                    }
                } else {
                    t = [data]
                }

                return {
                    ...product,
                    teeth: t,
                    units: t.length,
                    unit: t.length,
                    total_amount: t.length * product.unitPrice
                };
            }
            return product;
        });


        setOrder((prevState) => ({
            ...prevState,
            products: updatedProduct,
        }));
    }

    const validatepatient = async() => {
        const obj = {
            client: order.client,
            patient: order.patient_name
        }
        setValidationData(null)
        if(obj.client && obj.patient){
            const res = await post(`${ORDER_URL}/validateorder`, obj);
            if(res.status == 200) {
                setValidationData(res.response)
            }
        }
    }
    
    return (
        <Drawer
            open={isOpen}
            onClose={onDrawerClose}
            direction={Position}
            style={{
                width: `${width}%`
            }}
        >
            {
                isLoadComplete ?
                <>
                    <div className="rightbar-title px-3 py-4">
                        <span className="right-bar-toggle float-end cursor-pointer" title="close" onClick={onDrawerClose}>
                            <i className="mdi mdi-close noti-icon"></i></span>
                        <h5 className="m-0">{isEditData ? title : (worktype == 'corr' ? 'CORRECTION' : worktype.toUpperCase())} ORDER</h5>
                    </div>
                    <hr className="my-0"></hr>
                    
                    <div style={{padding: '20px', height: '98vh', overflow: 'scroll'}}>
                        {/* ROW_1 */}
                            <div className="row">
                                {/* 
                                    DIV_1
                                    Client
                                    Case Number
                                    Patien name
                                    Patien Age
                                */}
                                <div className={`col-md-4 mb-3`}>
                                    <div className='card' style={{padding: '10px'}}>
                                        <b>{worktype == 'corr' ? 'CORRECTION' : worktype.toUpperCase()} CASE NO </b>
                                        <span style={{borderBottom: '3px solid #f4f4f4'}}>
                                            <h2>
                                                <span className={`badge bg-dark`}>
                                                { editableObj ? editableObj.case_no : clientData.caseNo}
                                                </span>
                                            </h2>
                                        </span>
                                        <span id="err_new"></span>
                                        <label>Client Name</label>
                                        <h2>{clientData?.client}</h2>
                                        
                                        <div style={{display: 'flex'}}>
                                            <div style={{width: '85%'}} >
                                                <label>Patient Name</label>
                                                <div className="input-group">
                                                    <input  type="text" value={order?.patient_name} className="form-control"onChange={(e) => {
                                                        setOrder(c => ({...c, patient_name : e.target.value })) 
                                                    }}/>

                                                    <button className="btn btn-primary" type="button" onClick={validatepatient} >Validate</button>
                                                </div>
                                            </div>
                                            <div style={{width: '15%', marginLeft: '10px'}} >
                                                <label>Age</label>
                                                <input type="number" name="patient_age" defaultValue={ order?.patient_age } onChange={ e => 
                                                    setOrder(c => ({...c, patient_age : e.target.value}))
                                                } className="form-control" />
                                            </div>
                                            <span id="err_new"></span>
                                        </div>
                                    </div>
                                </div>
                                {/* END DIV_1 */}
                                {/* DIV_2 */}
                                    <div className={`col-md-4`}>
                                        <div className='card' style={{padding: '10px'}}>
                                            <div className='row mb-3'>
                                                <div className={`col-md-6`}>
                                                    <label className="form-check-label" htmlFor="SwitchCheckSizelg">Full Mouth </label>
                                                    
                                                    <div className="form-check form-switch form-switch-lg" dir="ltr">
                                                        <input className="form-check-input" type="checkbox" defaultChecked={ editableObj ? parseInt(editableObj.is_full_mouth) : isFullMouth } onClick={() => 
                                                            setIsFullMouth(() => {
                                                                const day = !isFullMouth ? 
                                                                    moment().add(parseInt(10), 'days').format('DD-MM-YYYY HH:mm') :
                                                                    ( priority > 0 ? moment().add(priority, 'days').format('DD-MM-YYYY HH:mm') : "xx-xx-xxxx --:--")
                                                                setOrder(c => ({...c, due_date: day, is_full_mouth: true, full_mouth_type: "local"}))
                                                                return !isFullMouth
                                                            }
                                                        )}/>
                                                    </div>
                                                </div>
                                                {
                                                    isFullMouth > 0 && 
                                                    <div className={`col-md-6`} style={{display: 'flex', justifyContent: 'space-around', marginTop:'25px'}}>
                                                        <div className="form-check form-radio-primary">
                                                            <input className="form-check-input" type="radio" name="formRadioColor1" defaultChecked={true} onClick={ e => {
                                                                setOrder(c => ({...c, due_date: moment().add(parseInt(10), 'days').format('D-MM-YYYY HH:mm'), full_mouth_type: "local"}))
                                                            }} />
                                                            <label className="form-check-label" htmlFor="formRadioColor1">Local</label>
                                                        </div>
                                                        <div className="form-check form-radio-primary">
                                                            <input className="form-check-input" type="radio" name="formRadioColor1" onClick={ e => {
                                                                setOrder(c => ({...c, full_mouth_type: "pan_india", due_date: moment().add(parseInt(15), 'days').format('DD-MM-YYYY HH:mm')}))
                                                            }}/>
                                                            <label className="form-check-label" htmlFor="formRadioColor1">Pan India</label>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            <div className="mb-3">
                                                <label>Priority</label>
                                                <select
                                                    name="priority"
                                                    className="form-control select2"
                                                    disabled={isFullMouth}
                                                    defaultValue={order.priority}
                                                    onChange={ e => {
                                                        const day = e.target.value ?
                                                            moment().add(parseInt(e.target.value), 'days').format('DD-MM-YYYY HH:mm') :
                                                            "xx-xx-xxxx --:--"
                                                        setPriority(e.target.value)
                                                        setOrder(c => ({...c, due_date: day, priority: e.target.value}))
                                                    }
                                                }>
                                                    <option value="" key='PO_0'> --- </option>
                                                    {
                                                        _priorityOptions?.map( (v, i) => <option key={`PO_${i+1}`} value={v.days} data-id={`${v.id}`}>{v.title}</option> )
                                                    }
                                                </select>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label>Order Date</label>
                                                    <h5>{order?.order_date}</h5>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Due Date</label>
                                                    <h5>{order?.due_date}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                {/* END DIV_2 */}

                                {/* DIV_3 */}
                                    <div className={`col-md-4`}>
                                        <div className='card' style={{padding: '10px', minHeight: '220px'}}>
                                            <label>Shades</label>
                                            <Select
                                                defaultValue={order?.shade}
                                                options={options?.shade}
                                                onChange={(e) => {
                                                    if(!shadesArray.includes(e.value)) setShadesArray(prev => [...prev, e.value])
                                                }}
                                            />

                                            <div style={{height: '42px'}}>
                                                {
                                                    shadesArray.length ? shadesArray.map( (shade, i) => {
                                                        return <span key={`S_${i}`} title="remove shade" className="badge rounded-pill bg-primary m-1 cursor-pointer shade-badge" style={{padding: '8px 15px', fontSize:'14px'}} onClick={() => {
                                                            let temp = shadesArray.filter(item => item !== shade) 
                                                            setShadesArray(temp)
                                                            setOrder(c => ({...c, shades:temp}))
                                                        }} >{options?.shade.filter(v => v.value == shade)[0].label} <i className="ms-1 mdi mdi-close noti-icon" /></span>
                                                    }) : ''
                                                }
                                            </div>

                                            {/* Shade Note Input */}
                                            <input name="shade_note" placeholder="Shade Note" className="form-control" value={order?.shade_note} onChange={(e) => setOrder(c => ({...c, shade_note: e.target.value})) } />
                                            {/* End Shade Note Input*/}

                                            <div className="row mt-1">
                                                <label>Trails</label>
                                                <div className="col-md-12">
                                                    {
                                                        _trials?.map( (v,i) =>
                                                            <span key={"T_"+i} className={`badge rounded-pill ${(trailsArray.includes(v) || (order?.trials)?.includes(v) ) ? "bg-success" : "bg-secondary" } m-1 cursor-pointer shade-badge`} style={{fontSize:'16px', padding: '3px 15px'}} onClick={() => {
                                                                if(!trailsArray.includes(v)) setTrailsArray(prev => [...prev, v])
                                                                else trailsArray.map((k,i) => (k === v) && trailsArray.splice(i, 1))
                                                                setOrder(c => ({...c, trials:trailsArray.join(',')}))
                                                            }} > { v.toUpperCase() }</span>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                {/* END DIV_3 */}
                            </div>
                        {/* END ROW_1 */}
                        
                        {/* ROW_2 */}
                            <div className='row'>
                                <div className={`col-md-3`}>
                                    <div className='card' style={{minHeight: '280px', padding: '10px', border: '1px solid #f3f3f3'}}>
                                        <div className="mb-3">
                                            <label>Articulator Tag</label>
                                            <select className="form-control select2" defaultValue={0} onChange={(e) => setOrder((c) => ({...c, articulator_tag: e.target.value}) )}>
                                                { _articulatorTag?.map( (v, i) => <option value={v.id} key={`AT_${i}`}> {v.title} </option>) }
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <label style={{width: '100%'}}>
                                                SubDoctor
                                                <i onClick={e => {
                                                    setModalType("sub_doctor")
                                                    setIsOpenModal(!isOpenModal)
                                                } } className="fa fa-plus pull-right cursor-pointer" style={{marginTop: '5px',marginRight: '5px', float: 'right'}}></i>
                                            </label>
                                            {/* <input className="form-control" defaultValue={order?.sub_doctor} onChange={(e) => setOrder(prev => ({...prev, sub_doctor: e.target.value}))}/> */}
                                            {/* <select className="form-control select2" defaultValue={0} onChange={(e) => setOrder((c) => ({...c, sub_doctor: e.target.value}) )}>
                                                <option key={`SD_${0}`} value="">---</option>
                                                { subDoctorsOptions?.map( (v, i) => <option value={v.value} key={`SD_${i+1}`}> {v.label} </option>) }
                                            </select> */}

                                            <Select
                                                defaultValue={subDoctorsOptions.filter(v => v.value == order?.sub_doctor)}
                                                value={subDoctorsOptions.filter(v => v.selected)}
                                                options={subDoctorsOptions}
                                                onChange={(e) => setOrder(c => ({...c, sub_doctor: e.value}))}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label>Correction Template</label>
                                            <Select
                                                defaultValue={options?.correction_temp.filter(v => v.value == order?.correction_template)}
                                                options={options?.correction_temp}
                                                onChange={(e) => setOrder(c => ({...c, correction_template: e.value}))}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label>Remark</label>
                                            <textarea className="form-control" defaultValue={order?.remark} onChange={(e) => setOrder(c=> ({...c, remark: e.target.value}))} />
                                        </div>
                                        <div>
                                            <label>Lab Department</label>
                                                <Select
                                                    defaultValue={options?.lab_dep.filter(v => v.value == order?.lab_department)}
                                                    options={options?.lab_dep}
                                                    onChange={(e) => setOrder(c => ({...c, lab_department: e.value}))}
                                                />
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <label style={{width: '100%'}}>
                                                {"Enclosure"}
                                                <i className="fa fa-plus pull-right cursor-pointer" style={{marginTop: '5px',marginRight: '5px', float: 'right'}}
                                                onClick={() => {
                                                    setModalType("enclosure")
                                                    setIsOpenModal(!isOpenModal)
                                                }}></i>
                                            </label>
                                            {
                                                Object.keys(order?.enclosure)?.length ? Object.keys(order?.enclosure).map(v => `${v} : ${order?.enclosure[v]}`).join(', ') : ''
                                            }
                                        </div>
                                        {/* <div className="mb-3">
                                            <label>Manufacturer</label>
                                                <Select
                                                    defaultValue={order?.manufacturer}
                                                    options={options?.manufacturer}
                                                    onChange={(e) => setOrder(c => ({...c, manufacturer: e.value}))}
                                                />
                                        </div>
                                        <div>
                                            <label>Employee</label>
                                                <Select
                                                    defaultValue={order?.employee}
                                                    options={options?.emp}
                                                    onChange={(e) => setOrder(c => ({...c, employee: e.value}))}
                                                />
                                        </div> */}
                                    </div>
                                </div>
                                <div className={`col-md-9 card border`} style={{minHeight: '320px'}}>
                                    <div className="mb-12">
                                        <label style={{width: '100%', marginTop: '10px', fontSize:"18px"}}>
                                            Products

                                            <button className="btn btn-primary pull-right"  style={{float: 'right'}} onClick={() => {
                                                setModalType("add_product")
                                                setIsOpenModal(!isOpenModal)
                                            }}>
                                                <i role="button" className="fa fa-plus"></i> Add Product
                                            </button>
                                        </label>
                                    </div>
                                    <div className="table-responsive mt-2">
                                        <table className="table table-bordered">
                                            <tbody>
                                                {
                                                    order?.products.length > 0 ?
                                                    order?.products?.map( (v, i) => {
                                                        t = (v.units > 0 && v.teeth?.length == 0) ? v.units: (typeof v.teeth == "string" ? v.unit : v.teeth.length)
                                                        return <React.Fragment key={`product_${v.id}`}>
                                                            {
                                                                i == 0 &&
                                                                <tr>
                                                                    <th>Product Category</th>
                                                                    <th>Product Type</th>
                                                                    <th>Product</th>
                                                                    <th>Teeth</th>
                                                                    <th>Units</th>
                                                                    <th>Unit Price</th>
                                                                    <th>Total</th>
                                                                </tr>
                                                            }
                                                            <tr key={`PL_${Math.round(Math.random() * 100)}`}>
                                                                <td>
                                                                    <Select
                                                                        menuPortalTarget={document.body} 
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        defaultValue={{ label: v.categoryTitle, value: v.product_category }}
                                                                        options={categories.map( c => ({label: c.title, value: c.code}))}
                                                                        onChange={(e) => handlePropertyChange(e, i, 'category', 'category')}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Select
                                                                        menuPortalTarget={document.body} 
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        defaultValue={{ label: v.categoryTypeTitle, value: v.categoryType }}
                                                                        options={category_types.map( c => ({label: c.title, value: c.code}))}
                                                                        onChange={(e) => handlePropertyChange(e, i, 'categoryType', 'category_type')}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Select
                                                                        menuPortalTarget={document.body} 
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                        defaultValue={{ label: v.productListTitle, value: v.productList }}
                                                                        options={product_list.map( c => ({label: `${c.title} (${c.final_price})`, value: c.code, price: c.final_price}))}
                                                                        onChange={(e) => handlePropertyChange(e, i, 'productList', 'product_id')}
                                                                    />
                                                                </td>
                                                                <td style={{width: '100px'}}>
                                                                    {
                                                                        (v.units > 0 && v.teeth?.length == 0) ?
                                                                        <table key={`alg_${Math.round(Math.random() * 100)}`} className={`teeth_table`}>
                                                                            <tbody>
                                                                                <tr key={`AL_${Math.round(Math.random() * 100)}`}>
                                                                                    <td>
                                                                                        Upper Aligner
                                                                                    </td>
                                                                                    <td>
                                                                                        {getAligners(v.aligners).upperSet}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr key={`AL_${Math.round(Math.random() * 100)}`}>
                                                                                    <td>
                                                                                        Lower Aligner
                                                                                    </td>
                                                                                    <td>
                                                                                        {getAligners(v.aligners).lowerSet}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table> : <Teeth values={v.teeth} callback={(data) => handleChangeTeeth(data, i)} />
                                                                    }
                                                                </td>
                                                                <td>{t}</td>
                                                                <td>
                                                                    <input type="number" defaultValue={v.unitPrice} onBlur={(e) => handlePriceEdit(e, i)} />
                                                                </td>
                                                                <td>{v.unitPrice * t}</td>
                                                            </tr>
                                                            <tr key={`PLN_${i}`}>
                                                                <td colSpan="6"><label>Margins</label> : NA, </td>
                                                                <td><button type="button" onClick={() => removeProductFromOrder(v, i)} className="btn btn-danger"><i className="fas fa-trash-alt" /></button></td>
                                                            </tr>
                                                        </React.Fragment>
                                                    }) : <tr><td colSpan={7} style={{textAlign:"center"}}>No Products Added</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                <div className="mt-3 d-grid">
                                    <button
                                        style={{height: '75px'}}
                                        className="btn btn-primary"
                                        type="submit"
                                        disabled={order.products.length <= 0}
                                        onClick={() => {
                                            addOrderMethods()
                                        }}
                                    >{isType == false ? 'Submit' : 'Update'}</button>
                                </div>
                                </div>
                            </div>
                        {/* END ROW_2 */}
                    </div>
                    
                    {/* Modal Popup */}
                    {
                        (modalType != "sub_doctor" && isOpenModal) &&
                        <Modal
                            zIndex="9999"
                            size={modalType == "add_product" ? 'xl' : 'lg'}
                            isOpen={isOpenModal}
                            toggle={() => setIsOpenModal(!isOpenModal)}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">{ getTitle(modalType) }</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            
                            <div className="modal-body">
                                { modalType == "add_product" && <AddProduct products={getProducts} clientData={clientData} /> }
                                { modalType == "enclosure" && <Trials _enclosures={order?.enclosure} enclosuresCB={encCB} /> }
                            </div>

                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsOpenModal(!isOpenModal)
                                    }}
                                    className="btn btn-secondary "
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                            </div>
                        </Modal>
                    }
                    
                    <ValidateModal isOpen={validationData && validationData.length ? true : false} data={validationData} callback={(isContinue) => {
                        setValidationData(null);
                        if(isContinue){
                            onDrawerClose()
                        }
                    }} />

                    {/* Sub Doctor Add Modal */}
                    { (modalType == "sub_doctor" && isOpenModal) && <SubDoctor data={clientData} isOpenModal={isOpenModal} callback={() => {
                        getSubDoctors(clientData?.code);
                        setModalType(false)
                        setIsOpenModal(false)
                    }} closeModal={() => setIsOpenModal(false)} /> }
                </>
                : <h3 className="p-4"><i className="me-2 fas fa-spinner fa-spin"/>form loading...</h3>
            }

        </Drawer>
    )
}

export default OrderForm;