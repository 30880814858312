import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Col,
    Container,
    Modal,
    Row,
    Card,
    CardBody
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import Datatable from "components/Datatables/datatable";
import FileUploader from "components/FileUploader";
import DeleteConfirmationModel from "components/delete-confirmation-model";
import { get, post } from 'helpers/api_helper';
import { customFormatDate } from 'helpers/basic_helper';
import { ORDER_URL, FILTERS } from 'helpers/url_helper';
import { useReactToPrint } from 'react-to-print';
import { getJobCard, getOrders } from "store/actions";
import OTModal from "./components/ordertransferModal";
import Rxmodal from "./components/rximagemodal";
import Teeth from './components/teeth';
import OrderForm from './form';
import Labslip from "./labslip";
import Select from 'react-select';
import ClientDropdown from "utils/client_dropdown";
import "flatpickr/dist/themes/material_red.css"
import Flatpickr from "react-flatpickr"
import Timezone from "utils/timezone";
import moment from "moment";
import { sortDateFilters } from 'constants/layout'

const Barcode = require('react-barcode');
const isType = 'order'
const pageStyle = `
    @page {
        width: "100px"
    }

    @media all {
        .pagebreak {
        display: none;
        }
    }

    @media print {
        .pagebreak {
        page-break-before: always;
        }
    }
`;

// Debounce function to limit the frequency of search updates
function debounce(func, delay) {
    let timerId;
    return function (...args) {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}

function removeBodyCss() {
    document.body.classList.add("no_padding");
}

const worktype_options = [
    {label: "New", value: "new"},
    {label: "Redo", value: "redo"},
    {label: "Correction", value: "curr"}
]

const colors = ['info', 'danger', 'primary', 'dark'];

const Orders = () => {
    const jobcardRef = useRef(null);
    const labslipRef = useRef(null);
    const searchRef = useRef(null);
    const dispatch = useDispatch()

    const { loading, orders } = useSelector(state => state.Orders);
    const [open, isOpen] = useState(false)
    const [editableObj, setEditableObj] = useState(false)
    const [loaderRowId, setLoaderRowId] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isAdd, setIsAdd] = useState(loading)
    const [jobcardModal, setJobcardModal] = useState(false)
    const [isJobCardLoading, setIsJobCardLoading] = useState(false)
    const [ordertranserLoader, setOrdertranserLoader] = useState(false)
    const [isEditLoading, setIsEditLoading] = useState(false)
    const [isLabSlipLoading, setIsLabSlipLoading] = useState(false)
    const [clientModelOpen, setClientModelOpen] = useState(false)
    const [OTModelOpen, setOTModelOpen] = useState(false)
    const [clientModelData, setClientModelData] = useState(false)
    const [labslipModel, setLabslipModel] = useState(false)
    const [clientID, setClientID] = useState(null)
    const [jobCarddata, setJobCarddata] = useState(null)
    const [labslipData, setLabslipData] = useState(null)
    const [isRedoOrder, setIsRedoOrder] = useState(null)
    const [isCorrectionOrder, setIsCorrectionOrder] = useState(null)
    const [workType, setWorkType] = useState('new')
    const [rxModel, setRXModel] = useState(false)
    const [isDeleteModel, setIsDeleteModel] = useState(false)
    const [isRXImage, setRXImage] = useState(false)
    const [isEditData, setIsEditData] = useState(false)
    const [searchclients, setSearchClients] = useState(false);
    const [localOrders, setLocalOrders] = useState(null);
    const [localOrdersCount, setLocalOrdersCount] = useState(0);
    const [orderNumber, setOrderNumber] = useState(null);
    const [selectedRangeValue, setSelectedRangeValue] = useState(null);
    const [filters, setFilters] = useState({
        'search': null,
        'client': null,
        'worktype': null,
        'department': null,
        'category': null,
        'producttype': null,
        'product': null,
        'bydate': null,
    });

    const [filtersOptions, setFiltersOptions] = useState([]);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(1);

    useEffect(() => setIsLoading(loading),[loading])

    const getFilterOptions = async()  => {
        const res = await get(FILTERS);
        if(res.status == 200){
            setFiltersOptions(res?.response)
        }
    }

    useEffect(() => {
        getFilterOptions();
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (searchRef.current) {
            searchRef.current.focus();
        }
        setSearchClients(clientModelData)
    }, [clientModelData])

    useEffect(() => {
        if(open === false){
            const filterObj = {
                'search': filters?.search || '',
                '_startdate': filters?.bydate ? filters?.bydate[0] : '',
                '_enddate': filters?.bydate ? filters?.bydate[1] : '',
                'client': filters?.client?.value || '',
                'worktype': filters?.worktype?.value || '',
                'category': filters?.category?.value || '',
                'producttype': filters?.producttype?.value || '',
                'product': filters?.product?.value || '',
                'department': filters?.department?.value || '',
            }
            
            const queryParams = new URLSearchParams(filterObj).toString();
            dispatch(getOrders({url:`${ORDER_URL}?${queryParams}&q=${""}&limit=${sizePerPage}&page=${page}`}))
        }
    },[open])

    useEffect(() => {
        setLocalOrders(orders?.rows || [])
        setLocalOrdersCount(orders?.totalRows || 0)
    }, [orders])

    // On Click Add Button Client Modal Data Load and popup
    const handleAddButton = async(isOT= false) => {
        const res = await get('/api/clients/?q=all')
        if(res.status === 200) {
            if(typeof res.response != 'string'){
                setClientModelData(res.response)
                if(isOT && typeof isOT != 'object') return true;
                setClientModelOpen(true)
            }
        }
    }
    
    const handleFilterChange = (value) => {
        let startDate = moment().endOf('day');
        let endDate = moment().endOf('day');

        switch (value) {
            case 'today':
                startDate = moment().startOf('day');
                break;
            case 'yesterday':
                startDate = moment().subtract(1, 'days').startOf('day');
                endDate = moment().subtract(1, 'days').endOf('day');
                break;
            case 'last_7_days':
                startDate = moment().subtract(7, 'days').startOf('day');
                break;
            case 'last_15_days':
                startDate = moment().subtract(15, 'days').startOf('day');
                break;
            case 'this_month':
                startDate = moment().startOf('month');
                break;
            case 'last_month':
                startDate = moment().subtract(1, 'months').startOf('month');
                endDate = moment().subtract(1, 'months').endOf('month');
                break;
            case 'last_45_days':
                startDate = moment().subtract(45, 'days').startOf('day');
                break;
            case 'last_60_days':
                startDate = moment().subtract(60, 'days').startOf('day');
                break;
        }
        
        setFilters(prev => ({...prev, bydate: [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')]}))
    }
    
    // Update Data , Get Single ROw using url params ?q=?
    const handleEdit = async(data) => {
        setIsEditLoading(!isEditLoading)
        setLoaderRowId(data?.id)
        const res = await get(`${ORDER_URL}?order=${data?.id}`)
        if(res.status == 200){
            setIsEditData(true)
            setEditableObj(res.response)
            isOpen(!open)
        }
    }

    // Remove Data
    const handleRemove = async(q, status, code) => {
        if(q != ''){
            setIsDeleteModel({url: `${ORDER_URL}/delete?q=${q}&status=${status}` , q, status, code, other: true})
        }
    }

    const hideModel = () => {
        setIsDeleteModel(false)
        dispatch(getOrders({url:`${ORDER_URL}?q=${""}&limit=${sizePerPage}&page=${page}`}))
    }
    
    // Generate JobCard
    function handleJobCard(orderNumber, id) {
        const cbJobcard = (data) => {
            setJobCarddata(data)
            setIsJobCardLoading(false)
            setJobcardModal(!jobcardModal);
            removeBodyCss();
        }
        
        setIsJobCardLoading(!isJobCardLoading)
        setLoaderRowId(id)
        dispatch(getJobCard({url:`${ORDER_URL}/getJobCard/${orderNumber}`}, cbJobcard))
    }

    // Lab Slip
    const handleLabSlip = async(orderNo) => {
        const res = await get(`/api/shipment/labslip/${orderNo}`)
        if(res.status == 200){
            setLabslipData(res.response)
            setIsLabSlipLoading(!isLabSlipLoading)
            setLabslipModel(!labslipModel)
            setLoaderRowId(orderNo)
        }
    }

	const handleGeneratePdf = useReactToPrint({
        content: () => labslipRef.current
    })

    const handlePrint = useReactToPrint({
        content: () => jobcardRef.current,
    });

    const handleRedoOrder = async(orderNo, ID) => {
        setLoaderRowId(ID)
        setIsRedoOrder(true)
        const res = await get(`${ORDER_URL}?order=${ID}`)
        if(res.status == 200){
            setWorkType('redo')
            setEditableObj(res.response)
            isOpen(!open)
            setLoaderRowId(null)
            setIsRedoOrder(false)
        }
    }
    
    const handleCorrectionOrder = async(orderNo, ID) => {
        setLoaderRowId(ID)
        setIsCorrectionOrder(true)
        const res = await get(`${ORDER_URL}?order=${ID}`)
        if(res.status == 200){
            setWorkType('corr')
            setEditableObj(res.response)
            isOpen(!open)
            setLoaderRowId(null)
            setIsCorrectionOrder(false)
        }
    }

    const handleRXUpload = (order_number, id) => {
        setRXModel({isOpen:true, ORNO: order_number, ID:id})
    }

    const handleOrdertrasferModal = async(orderNo, id) => {
        setLoaderRowId(id);
        setOrdertranserLoader(true);
        const res = await handleAddButton(true);
        
        if(res){
            setOTModelOpen(true)
            setOrderNumber(orderNo)
            setLoaderRowId(null);
            setOrdertranserLoader(false);
        }
    }

    const handleOrdertrasfer = async(client) => {
        const res = await post('api/orders/changeclient', {client, orderNumber});
        
        if(res.status === 200) {
            setOTModelOpen(false)
            setLoaderRowId(null);
            setOrderNumber(null);
            setClientModelData(null);
            dispatch(getOrders({url:`${ORDER_URL}?q=${""}&limit=${sizePerPage}&page=${page}`}))
        }
    }

    // table Column Data
    let tableColumns = [
        {
            dataField: 'sr_no',
            text: '#',
            sort: true
        },
        {
            dataField: 'order_number',
            text: 'Order #',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => {
                switch (row['type']) {
                    case 'corr':
                        return <>
                            <i style={{fontSize: "16px"}} className={`fas fa-dot-circle me-2 text-${colors[parseInt(row.priority)-1]}`}> </i>
                            <span style={{fontSize: '16px'}} title="Order Edit on Click" className="label text-warning" onClick={() => handleEdit(row)}>
                                {row.order_number}
                            </span>
                            <br />
                            OF: {row.orderformdate ? <span style={{fontSize: '14px', marginTop: '6px'}} className="badge bg-primary">{customFormatDate(row?.orderformdate, 'DD MMM, YY')} <a target="_blank" rel="noreferrer"  href={`/orderform/print?_orderform=${row?.orderform}`}><i className="fas text-white fa-info-circle ms-2" title="View Order form"></i></a></span> : 'Not Created'}
                        </>
                    case 'redo':
                        return <>
                            <i style={{fontSize: "16px"}} className={`fas fa-dot-circle me-2 text-${colors[parseInt(row.priority)-1]}`}> </i>
                            <span style={{fontSize: '16px'}} title="Order Edit on Click" className="text-danger" onClick={() => handleEdit(row)}>
                                {row.order_number}
                            </span>
                            <br />
                            OF: {row.orderformdate ? <span style={{fontSize: '14px', marginTop: '6px'}} className="badge bg-primary">{customFormatDate(row?.orderformdate, 'DD MMM, YY')} <a target="_blank" rel="noreferrer"  href={`/orderform/print?_orderform=${row?.orderform}`}><i className="fas text-white fa-eye ms-2" title="View Order form"></i></a></span> : 'Not Created'}
                        </>
                    default:
                        return <>
                            <i style={{fontSize: "16px"}} className={`fas fa-dot-circle me-2 text-${colors[parseInt(row.priority)-1]}`}> </i>
                            <span style={{fontSize: '16px'}} title="Order Edit on Click" className="badge bg-success" onClick={() => handleEdit(row)}>
                                {row.order_number} 
                            </span>
                            <br />
                            OF: {row.orderformdate ? <span style={{fontSize: '14px', marginTop: '6px'}} className="badge bg-primary">{customFormatDate(row?.orderformdate, 'DD MMM, YY')} <a target="_blank" rel="noreferrer"  href={`/orderform/print?_orderform=${row?.orderform}`}><i className="fas text-white fa-eye ms-2" title="View Order form"></i></a></span> : 'Not Created'}
                        </>
                }
            }
        },{
            dataField: 'order_date',
            text: 'Order Date',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => customFormatDate(row?.order_date, 'DD MMM, YY')
        },{
            dataField: 'case_no',
            text: 'Model #',
            sort: true
        },{
            dataField: 'client',
            text: 'Client',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => {
                return <strong>{row.client} ({row.client_code})</strong>
            }
        },{
            dataField: 'products',
            text: 'Products',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => {
                return <strong>{row?.products?.split(',').map((text, index) => (
                    <li key={`${row.id}_${index}`}>{text}</li>
                ))}</strong>
            }
        },{
            dataField: 'shade',
            text: 'Shade',
            sort: false,
            formatter: (cell, row,  rowIndex, extraData) => {
                const shadeTitle = JSON.parse(row.shade).map(st => orders?.shades?.filter(s => st == s.code)[0]?.title);
                return <strong>{shadeTitle.join(', ')}</strong>
            }
        },{
            dataField: 'patient',
            text: 'Patient',
            sort: true
        },{
        dataField: 'remark',
            text: 'Remark',
            sort: false
        },{
            dataField: 'actions',
            text: "JC / LS",
            isDummyField: true,
            formatExtraData: [ isJobCardLoading, isLabSlipLoading, ordertranserLoader ],
            formatter: (cell, row,  rowIndex, extraData) => {
                return <div>
                    <Link
                        to="#"
                        className="btn btn-outline-success btn-sm JobCard"
                        onClick={() => handleOrdertrasferModal(row.order_number, row.id)}
                        title="Change Client" >
                        <i className={`fas ${(loaderRowId == row.id && ordertranserLoader) ? 'fa-spinner fa-pulse' : 'fa-forward'}`}></i>
                    </Link>
                    {" "}
                    <Link
                        to="#"
                        onClick={() => handleLabSlip(row.order_number)}
                        className="btn btn-primary btn-sm labslip"
                        title="Create Lap slip" >
                        <i className={`fas ${(loaderRowId == row.id && isLabSlipLoading) ? 'fa-spinner fa-pulse' : 'fa-id-badge'}`}></i>
                    </Link>
                    {
                        (['corr', 'new'].includes(row['type'])) && 
                        <Link
                        to="#"
                        className="ms-1 btn btn-sm btn-primary btn_rc" >
                            <i onClick={() => handleRedoOrder(row.order_number, row.id)} title="Redo Order" className={`${(loaderRowId == row.id && isRedoOrder) ? 'fas fa-spinner fa-pulse' : 'mdi mdi-alpha-r'} text-light f-17`}></i>
                        </Link>
                    }
                    {" "}
                    {
                        (['redo', 'new'].includes(row['type'])) && 
                        <Link
                            to="#"
                            className="btn btn-sm btn-warning btn_rc" >
                            <i onClick={() => handleCorrectionOrder(row.order_number, row.id)} title="Currection Order" className={`${(loaderRowId == row.id && isCorrectionOrder) ? 'fas fa-spinner fa-pulse' : 'mdi mdi-alpha-c'} text-light f-17`}></i>
                        </Link>
                    }
                </div>
            }
        }, {
            dataField: 'actions3',
            text: "",
            isDummyField: true,
            formatExtraData: [ isJobCardLoading, isEditLoading, isLabSlipLoading ],
            formatter: (cell, row,  rowIndex, extraData) => {
                return <div style={{float:'right'}}>
                    <Link
                        to="#"
                        className={`btn btn-${row.status == 0 ? "danger" : "success"} btn-sm edit`}
                        onClick={() => handleRemove(row.id, row.status == 1? 0 : 1, row.order_number)}
                        title={row.status == 1 ?'restore' : 'delete'} >
                        <i className={`fas fa-trash-alt`}></i>
                    </Link>
                </div>
            }
        },
    ];

    const changeLabslipModelStatus = () => {
            setLabslipData(null)
            setIsLabSlipLoading(!isLabSlipLoading)
            setLabslipModel(!labslipModel)
            setLoaderRowId(null)
    }

    // Debounce the setSearchClients function to limit the frequency of updates
    const debouncedSearch = debounce((searchValue) => {
        if (searchValue.length > 3) {
            setSearchClients(clientModelData.filter(v =>
                v.clientname.toLowerCase().includes(searchValue.toLowerCase()) ||
                v.code.toLowerCase().includes(searchValue.toLowerCase()) || 
                (v.clientname + " (" + v.code + ")").toLowerCase().includes(searchValue.toLowerCase())
            ));
        } else if (searchValue.length === 0) {
            setSearchClients(clientModelData);
        }
    }, 300);

    const handleSearch = (event) => {
        const searchValue = event.target.value;
        debouncedSearch(searchValue);
    };

    const handleKeyDown = (event) => {
        if (event.altKey && event.key === 'a') {
            handleAddButton();
        }
    };

    const filterOrderHandler = (data = null) => {
        const filterObj = {
            'search': filters?.search || '',
            '_startdate': filters?.bydate ? filters?.bydate[0] : '',
            '_enddate': filters?.bydate ? filters?.bydate[1] : '',
            'client': filters?.client?.value || '',
            'worktype': filters?.worktype?.value || '',
            'category': filters?.category?.value || '',
            'producttype': filters?.producttype?.value || '',
            'product': filters?.product?.value || '',
            'department': filters?.department?.value || '',
        }

        if(data?.page){
            setSizePerPage(data.sizePerPage)
            setPage(data.page)
        }
        const queryParams = new URLSearchParams(filterObj).toString();
        dispatch(getOrders({url:`${ORDER_URL}?${queryParams}&q=${""}&limit=${data?.sizePerPage || sizePerPage}&page=${data?.page || page}`}))
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{isType} | KS-Lab</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title="Masters"
                        breadcrumbItem={isType}
                    />

                    <Row>
                        <Col xl='12'>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xl="2">
                                            <label>Search by Order or Modal Number</label>
                                            <input type="text" className="form-control" onChange={(e) => setFilters(prev => ({...prev, search: e.target.value}))} placeholder="Please Search using Order or Modal Number" />
                                        </Col>
                                        <Col xl="2">
                                            <ClientDropdown callback={(e) => setFilters(prev => ({...prev, client: e})) } />
                                        </Col>
                                        <Col xl={2}>
                                            <label>Work type</label>
                                            <Select
                                                onChange={(e) => {
                                                    setFilters(prev => ({...prev, worktype: e}))
                                                }}
                                                isClearable={true}
                                                value={filters?.worktype }
                                                options={worktype_options}
                                                name='clients'
                                                required={false}
                                                placeholder={'Select Worktype'}
                                            />
                                        </Col>
                                        <Col xl={2}>
                                            <label>Day wise Filter</label>
                                            <Select
                                                onChange={(e) => {
                                                    setSelectedRangeValue(e)
                                                    handleFilterChange(e.value)
                                                }}
                                                isClearable={true}
                                                value={selectedRangeValue || '' }
                                                options={sortDateFilters || []}
                                                name='clients'
                                                required={false}
                                                placeholder={'Select Filter'}
                                            />
                                        </Col>
                                        <Col xl={4}>
                                            <label>Date Range</label>
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="DD-MM-YYYY"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "d-m-Y"
                                                }}
                                                onChange={
                                                    (e) => {
                                                        const dates = e.map(dateString => Timezone(dateString));
                                                        setFilters(prev => ({...prev, bydate: dates}))
                                                    }
                                                }
                                            />
                                        </Col>
                                    </Row><br />
                                    <Row>
                                        <Col xl={3}>
                                            <label>Department</label>
                                            <Select
                                                onChange={(e) => setFilters(prev => ({...prev, department: e}))}
                                                isClearable={true}
                                                value={filters?.department || '' }
                                                options={filtersOptions?.departments || []}
                                                name='clients'
                                                required={false}
                                                placeholder={'Select Department'}
                                            />
                                        </Col>
                                        <Col xl={3}>
                                            <label>Product Category</label>
                                            <Select
                                                onChange={(e) => setFilters(prev => ({...prev, category: e}))}
                                                isClearable={true}
                                                value={filters?.category || '' }
                                                options={filtersOptions?.categories || []}
                                                name='categorie'
                                                required={false}
                                                placeholder={'Select Product Category'}
                                            />
                                        </Col>
                                        <Col xl={3}>
                                            <label>Product Type</label>
                                            <Select
                                                onChange={(e) => setFilters(prev => ({...prev, producttype: e}))}
                                                isClearable={true}
                                                value={filters?.producttype || '' }
                                                options={filtersOptions?.types || []}
                                                name='clients'
                                                required={false}
                                                placeholder={'Select Product Type'}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="text-end">
                                            <button className="btn btn-primary" onClick={filterOrderHandler}>Filter Orders</button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="12" style={{minHeight: '60vh'}}>
                            {
                                localOrders ? 
                                <Datatable
                                    keyField={'id'}
                                    title={`All ${isType}`}
                                    handleAddButton={handleAddButton}
                                    columns={tableColumns}
                                    rows={localOrders}
                                    rowsLength={localOrdersCount}
                                    loading={loading}
                                    isSearch={true}
                                    ssr={filterOrderHandler}
                                />
                                :<div id="status" style={{height: '100vh', width: 'auto'}}>
                                    <div className="spinner-chase">
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                        <div className="chase-dot"></div>
                                    </div>
                                    <div style={{margin: '30px auto', textAlign: 'center'}}>
                                    Orders Loading Please Wait...
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>

            {
                open &&
                <OrderForm
                    title={`${editableObj ? 'Edit' : 'Add'}`}
                    Position={"right"}
                    isOpen={open}
                    client={clientID}
                    editableObj={editableObj}
                    worktype={workType}
                    isEditData={isEditData}
                    onDrawerClose={() => {
                        setIsEditLoading(!isEditLoading)
                        setIsEditData(false)
                        isOpen(false)
                        setEditableObj(false)
                    }}
                />
            }

            {/* JobCard Model */}
            {
                jobcardModal ?
                <Modal
                    size="md"
                    isOpen={jobcardModal}
                    toggle={() => {
                        setJobcardModal(!jobcardModal);
                        removeBodyCss();
                    }}
                    centered={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" > Job Card </h5>
                        <button
                            onClick={() => {
                                setJobcardModal(!jobcardModal);
                                removeBodyCss();
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="col-xs-12" ref={jobcardRef} style={{pageStyle}}>
                            <table style={{
                                    border: "1px solid #000",
                                }} width="100%">
                                <tbody>
                                    <tr>
                                        <td style={{ position: 'relative', bottom: '-25px'}}>
                                            {/* <b>&nbsp;Company</b> ( location ) <br /> */}
                                            <b>&nbsp;Dr. : {jobCarddata?.client.clientname} </b> <br />
                                        </td>
                                        <td style={{width:"20%"}}>
                                            <Barcode value={jobCarddata?.jobcard['jcno']} width={1} textAlign= "left" height={40}/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="my-1" style={{
                                    border: "1px solid #000",
                                }} width="100%">
                                <tbody>
                                    <tr>
                                        <td><b>&nbsp;OD :</b>&nbsp;&nbsp;{customFormatDate(jobCarddata?.order_date, "DD-MM-YYYY HH:MM A")}</td>
                                        <td style={{float: "right"}}><b>&nbsp;DD :</b>&nbsp;&nbsp;{customFormatDate(jobCarddata?.due_date, "DD-MM-YYYY HH:MM A")}</td>
                                    </tr>
                                    <tr>
                                        <td><b>&nbsp;Patient Name :</b> &nbsp;{jobCarddata?.patient_name}</td>
                                        <td style={{float: "right"}}><b>&nbsp;NEW</b></td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="my-1" style={{
                                    border: "1px solid #000"
                                }} width="100%">
                                <tbody>
                                    <tr>
                                        <td width="40%">
                                            <b>&nbsp;Shades :</b>
                                            <br />&nbsp; {jobCarddata.shades ? jobCarddata?.shades?.map(v => v.title)?.join(', ') : "---, ---, ---"}
                                        </td>
                                        <td style={{float: "right"}}>
                                            <Teeth isJobcard={true} values={jobCarddata?.teeths.map(v => JSON.parse(v)).reduce((prev, current) => [...prev, ...current])} isBorder={true}/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                                
                            {/* <table border="1" width="100%">
                                <tbody>
                                    <tr>
                                        <td>&nbsp;PFM Non Precious[ 34 ]<br /></td>
                                    </tr>
                                </tbody>
                            </table> */}

                            <table className="my-1" style={{
                                    border: "1px solid #000"
                                }} width="100%">
                                <tbody>
                                    <tr>
                                        {/* <td><b>&nbsp;{JSON.parse(jobCarddata?.trial).join(', ')}</b></td> */}
                                        {/* <td style={{float:"right"}}><b>OR</b> : 9.09%,  <b>OC</b> : 27.27% </td> */}
                                    </tr>
                                    <tr><td colSpan="2"><b>&nbsp;Enclosure :</b>&nbsp;&nbsp; {jobCarddata?.enclosure}</td></tr>
                                    <tr><td colSpan="2"><b>&nbsp;Standing INT :</b>&nbsp;&nbsp;</td></tr>
                                    <tr><td colSpan="2"><b>&nbsp;Correction Temp :</b>&nbsp;&nbsp;{jobCarddata?.correction_template}</td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                                setJobcardModal(!jobcardModal);
                                removeBodyCss();
                            }} >Close</button>
                        <button 
                            type="button"
                            title="Print JobCard"
                            onClick={ () => {
                                handlePrint()
                            }}
                            className="btn btn-primary"><i className="fa fa-print"/> Print</button>
                    </div>
                </Modal> : ''
            }

            {/* labslip Model */}
            {
                labslipModel &&
                <Modal
                    size="md"
                    isOpen={labslipModel}
                    toggle={() => changeLabslipModelStatus()}
                    centered={true}
                >
                    <div className="modal-body">
                        <div id="printablediv" ref={labslipRef}>
                            <Labslip data={labslipData} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => changeLabslipModelStatus()} >Close</button>
                        <button
                            type="button"
                            title="Print JobCard"
                            onClick={handleGeneratePdf}
                            className="btn btn-primary"><i className="fa fa-print"/> Print</button>
                    </div>
                </Modal>
            }

            {/* RXUpload Model */}
            { rxModel && <FileUploader data={ rxModel } callback={ () => setRXModel(false)} /> }
            
            {/* RXImage Model */}
            { isRXImage && <Rxmodal imgUrl={ isRXImage } callback={ () => setRXImage(false)} /> }

            {/* Client List Model */}
            {
                clientModelOpen &&
                <Modal
                    size="lg"
                    isOpen={clientModelOpen}
                    toggle={() => {
                        setClientModelOpen(!clientModelOpen);
                        removeBodyCss();
                    }}
                    centered={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" > Select Client </h5>
                        <button
                            onClick={() => setClientModelOpen(false)}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <input ref={searchRef} type="text" className="form-control mb-2" placeholder="search by client name" onChange={handleSearch} />
                        
                        <div className="col-xs-12" style={{height: '350px', overflowY: 'scroll'}}>
                            <table className="table table-hover table-borderd" id="table">
                                <tbody>
                                    {
                                        searchclients?.map((v, i) => 
                                            <tr title={`Create Order for ${v.clientname} - ${v.address}`} key={`OCLD_${i}`} onClick={() => {
                                                setClientID(v.code)
                                                isOpen(!open)
                                                setClientModelData(false)
                                                setClientModelOpen(!clientModelData)
                                            }}>
                                                <td>{v.clientname} {" "} ({v.code}) <i className="fas fa-info-circle"></i></td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button 
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                                setClientModelOpen(false)
                            }} >Close</button>
                    </div>
                </Modal>
            }

            { OTModelOpen && <OTModal onChange={handleSearch} isOpen={OTModelOpen} clients={searchclients} isClose={() => setOTModelOpen(false)} onClick={handleOrdertrasfer} /> }

            { isDeleteModel && <DeleteConfirmationModel isOpen={true} changeState={hideModel} code={isDeleteModel} /> }

        </React.Fragment>
    )
}

export default Orders
