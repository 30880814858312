import Breadcrumbs from "components/Common/Breadcrumb"
import Notifications from "components/Notifications"
import DeleteConfirmationModel from "components/delete-confirmation-model"
import { getCode, userModuleData } from 'helpers/basic_helper'
import { MASTER_URL } from "helpers/url_helper"
import React, { useEffect, useState } from "react"
import Datatable from "components/Datatables/datatable"
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from 'react-redux'
import {
    Col,
    Container,
    Row
} from "reactstrap"
import { addRecord, getAllRows } from 'store/actions'
import FormComponent from "../FormComponent"
const Master_URL = `${MASTER_URL}${process.env.REACT_APP_PARENT_CLIENT}`

const isType = 'parentclient'
const ParentClient = props => {
    const dispatch = useDispatch()
    const { loading, parentclients } = useSelector(state => state.Master);
    const [open, isOpen] = useState(false)
    const [editableObj, setEditableObj] = useState(null)
    const [dataRows, setDataRows] = useState([])
    const [isDeleteModel, setIsDeleteModel] = useState(false)
    const [pagination, setPagination] = useState({page: 1, sizePerPage: 10});
    
    console.log(pagination)

    const [isEdit, setIsEdit] = useState(false)
    const [isActions, setIsActions] = useState(false)
    const [isActionColumn, setIsActionColumn] = useState(false)

    const fetchData = () => dispatch(getAllRows({url:`${Master_URL}&limit=${pagination.sizePerPage}&page=${pagination.page}`, isSingle: false, isType: isType}, props.history))

    useEffect(() => {
        setDataRows(parentclients?.data)
    },[parentclients])

    /**
     * Submit or Update data Api Call
     * @param {Form Data} data 
     * @method {post} POST 
     */
    const submitForm = (data) => {
        const checkCode = dataRows.filter(v => (v.code).toUpperCase() == (data.target.code.value).toUpperCase())
        if(data?.target?.id?.value =='' && checkCode.length > 0) {
            Notifications('please enter unique code.!', 'error')
            return;
        }

        const Obj = {
            "title": data.target.title.value,
            "code": data.target.code.value
        }
        dispatch(addRecord({isType:isType, Obj:Obj, url: Master_URL+`${data?.target?.id?.value ? '&q='+data?.target?.id?.value : '' }`, id: data?.target?.id?.value}, props.history, () => {
            getData()
            setIsEdit(false)
            isOpen(false)
            Notifications('You have successfully added a Client Parents.!')
        }))
    }

    /**
     * Get Single row using url params ?q=?
     * @param {Row_id} q 
     * @method {get} GET 
     */
    const handleEdit = q => {
        if(q > 0){
            setIsEdit(q)
            dispatch(getAllRows({url:`${Master_URL}&q=${q}`, isSingle: true}, props.history, (data) => {
                setEditableObj(data)
                isOpen(true)
            }))
        }
    }

    /**
     * Chnage Row Status By ID and condition Based Status passing,
     * @param {row_id} q 
     * @param {row Status} status 
     * @method {del} DELETE 
     */
    const handleRemove = (q, status, code) => {
        if(q != ''){
            setIsDeleteModel({url: process.env.REACT_APP_PARENT_CLIENT , q, status, code})
        }
    }

    const hideModel = () => {
        setIsDeleteModel(false)
        fetchData()
    }

    /**
     * DataTable with Condition based row formating
     */
    let datatableData = [
        {
            dataField: 'id',
            text: 'Id'
        }, {
            dataField: 'title',
            text: 'Parent'
        }, {
            dataField: 'code',
            text: 'Code'
        }, {
            dataField: 'actions',
            text: "",
            isDummyField: true,
            // formatExtraData: [ btnLoading.wpBtn, btnLoading.pdfBtn ],
            formatter: (cell, row) => <>
                <Link to="#" className="btn btn-outline-secondary btn-sm edit" onClick={() => handleEdit(row.id)} title="Edit" ><i className={`fas fa-pencil-alt`} /></Link>
                {" "}
                <Link to="#" className={`btn btn-${row.status == 0 ? "danger" : "success"} btn-sm edit`} onClick={() => handleRemove(row.id, row.status == 1 ? 0 : 1, row.code)} title={row.status == 0 ? 'delete' : 'restore'} ><i className={`fas fas fa-trash-alt`} /></Link>
            </>
        }
    ];

    /**
     * HTML Form Columns 
     */
    let htmlFormFields = [
        {
            name: "id",
            required: false,
            type: 'hidden',
            value: editableObj?editableObj.id : '',
            col:12
        },{
            name: "title",
            label: "Parent",
            placeholder: "Please Enter Parent Client",
            required: true,
            type: 'text',
            value: editableObj?editableObj.title : '',
            col:12
        },{
            name: "code",
            label: "Code",
            placeholder: "Please Enter Parent Client Code",
            required: true,
            disable: editableObj?.code? true : false,
            type: 'text',
            value: editableObj?editableObj.code:getCode("parent_client", dataRows?.length),
            col: 12
        }
    ]

    console.log(htmlFormFields)

    useEffect(async() => {
        const data =  await userModuleData('masters', 'locations')
        setIsActions(data)
        if(data && (data?.edit || data?.delete)){
            setIsActionColumn(true)
        }
    }, [])

    useEffect(() => {
        fetchData()
    }, [pagination]);

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags><title>Dashboard | {isType}</title></MetaTags>
                <Container fluid>
                    <Breadcrumbs pageTitle="Masters" title="Masters" breadcrumbItem={["Client", "Parents"]} />
                    <Row>
                        <Col xl="12">
                            <Datatable
                                keyField={'id'}
                                title={`Parent Client`}
                                isAdd={true}
                                placeholder={'Search by name, code'}
                                isSearch={true}
                                handleAddButton={isOpen}
                                columns={datatableData}
                                rows={dataRows?.rows || []}
                                rowsLength={parseInt(dataRows?.total_rows) || 0}
                                loading={loading}
                                ssr={setPagination}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            
            <FormComponent
                title={`${editableObj ? 'Edit' : 'Add'} Parent`}
                Position={"right"}
                isOpen={open}
                fileds={htmlFormFields}
                loading={loading}
                editableObj={editableObj}
                onDrawerClose={() => {
                    setEditableObj(null)
                    isOpen(!open)
                }}
                submitForm={submitForm}
            />

            { isDeleteModel && <DeleteConfirmationModel isOpen={true} changeState={hideModel} code={isDeleteModel} /> }

        </React.Fragment>
    )
}

export default ParentClient
